import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import TextOutlines from "./components/TextOutlines";
import GridOutlines from "./components/GridOutlines";
import FrameOutlines from "./components/FrameOutlines";
import "./App.css";
import Avatar from "./components/Avatar";
import { itemDecoration, Viewport, gridStyles } from "./style";
import GridIcon from "./components/icons/GridIcon";

const INITIAL_DELAY = 1500;
const SHOW_GRID_DUR = 2750;
const THEMES = 4;
const THEME_ATTRIBUTE = "data-theme";

const App = () => {
  const [isGridVisible, setGridVisible] = useState(false);
  const [isReady, setReady] = useState(false);
  const columnCount = window.innerWidth > 768 ? 12 : 4;

  useEffect(() => {
    setTimeout(() => {
      setGridVisible(true);
    }, INITIAL_DELAY);

    setTimeout(() => {
      setGridVisible(false);
      setReady(true);
    }, SHOW_GRID_DUR + INITIAL_DELAY);
  }, []);

  const handleClickTheme = () => {
    const theme = document.body.getAttribute(THEME_ATTRIBUTE);

    if (!theme) return document.body.setAttribute(THEME_ATTRIBUTE, "1");

    const themeIndex = parseInt(theme);
    document.body.setAttribute(THEME_ATTRIBUTE, `${(themeIndex + 1) % THEMES}`);
  };

  return (
    <Root>
      <Buttons isVisible={isReady} isGridActive={isGridVisible}>
        <Button onClick={() => setGridVisible(!isGridVisible)}>
          <GridIcon size={20} />
        </Button>
        <Button onClick={handleClickTheme}>
          <ThemeCircle />
        </Button>
      </Buttons>
      <Grid>
        <GridOutlines count={columnCount} isVisible={isGridVisible} />
        <FrameOutlines delay={100} isVisible={isGridVisible} />
        <Id>
          <Cap>
            <Avatar isStroked={isGridVisible} />
          </Cap>
          <FrameOutlines isVisible={isGridVisible} delay={100} />
          <TextOutlines>
            <p>Paul J. Noble</p>
          </TextOutlines>
        </Id>
        <Heading>
          <FrameOutlines isVisible={isGridVisible} delay={200} />
          <TitleWrap>
            <FrameOutlines isVisible={isGridVisible} delay={250} />
            <TextOutlines>
              <h1>ux design &amp; development</h1>
            </TextOutlines>
          </TitleWrap>
        </Heading>
        <Intro>
          <FrameOutlines isVisible={isGridVisible} delay={300} />
          <TextOutlines>
            <p>
              <br />
              Hello, I'm a UX engineer focused on creating interfaces for web
              and mobile apps. I've been doing this for over a decade at a
              variety of companies, big and small. Presently I'm fortunate to
              work with the good people at Google in Sydney.
            </p>
          </TextOutlines>
        </Intro>
        <Skills>
          <FrameOutlines isVisible={isGridVisible} delay={300} />
          <TextOutlines>
            <h3>Personal Projects</h3>
          </TextOutlines>
          <TextOutlines>
            <p>
              <a href="https://www.tmzn.co">tmzn.co</a>
              <br />
              <a href="https://desktop.tmzn.co">desktop.tmzn.co</a>
              <br />
              <a href="https://www.playpopular.com">playpopular.com</a>
            </p>
          </TextOutlines>
        </Skills>
        <Work>
          <FrameOutlines isVisible={isGridVisible} delay={100} />
          <TextOutlines>
            <h3>Experimental</h3>
          </TextOutlines>
          <TextOutlines>
            <p>
              <a href="https://distracted-kepler-f0ce67.netlify.app">
                3d football
              </a>
              <br />
              <a href="https://epic-spence-be822a.netlify.app">3d carousel</a>
              <br />
              <a href="https://keen-morse-3e17dc.netlify.app/">
                wheel carousel
              </a>
              <br />
              <a href="https://cdpn.io/paulnoble/debug/ZPQGwG">vortext</a>
              <br />
              <a href="https://cdpn.io/paulnoble/debug/qZaNRB">daylight map</a>
            </p>
          </TextOutlines>
        </Work>
        <Footer>
          <FooterInner>
            <FrameOutlines isVisible={isGridVisible} delay={150} />
            <TextOutlines>
              <p>
                <a href="https://www.linkedin.com/in/paul-noble-63a9405">
                  LinkedIn
                </a>
                &nbsp;&nbsp;∙&nbsp;&nbsp;
                <a href="https://www.instagram.com/pauljames01">Instagram</a>
                &nbsp;&nbsp;∙&nbsp;&nbsp;
                <a href="https://www.github.com/pauljnoble">Github</a>
                &nbsp;&nbsp;∙&nbsp;&nbsp;
                <a href="https://www.codepen.io/paulnoble">Codepen</a>
              </p>
            </TextOutlines>
          </FooterInner>
        </Footer>
      </Grid>
    </Root>
  );
};

const Root = styled.div`
  margin: 0 auto 0;
  max-width: var(--max-width);
  padding: 0 28px;
  min-height: 100vh;
  color: var(--color-text);

  @media (min-width: ${Viewport.LARGE}px) {
    margin-top: 0;
    padding: 0 40px;
  }
`;

const Cap = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  @media (min-width: ${Viewport.MEDIUM}px) {
    right: 0;
    top: 32px;
  }
`;

const visibleStyles = `
  opacity: 1; 
`;

const activeGridStyles = `
  > div:first-child > div {
    opacity: 1;
  }
`;

const Buttons = styled.div<{ isVisible: boolean; isGridActive: boolean }>`
  position: absolute;
  display: none;
  top: 26px;
  right: 16px;
  width: 80px;
  justify-content: space-between;
  transition: opacity 0.3s;
  transition-delay: 1s;
  opacity: 0;
  z-index: 1;
  ${(p) => p.isVisible && visibleStyles}
  ${(p) => p.isGridActive && activeGridStyles}

  @media (min-width: ${Viewport.LARGE}px) {
    display: flex;
  }
`;

const Button = styled.div`
  height: 36px;
  width: 36px;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child > div {
    opacity: 0.3;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.13);
  }
`;

const ThemeCircle = styled.div`
  background-color: var(--color-accent);
  height: 8px;
  width: 8px;
  border-radius: 50%;
`;

const Grid = styled.div`
  position: relative;
  ${gridStyles}
`;

const TitleWrap = styled.div`
  position: relative;
`;

const h1Styles = `
  color: var(--color-accent);
  font-size: 48px;
  letter-spacing: -2px;
  line-height: 1;
  margin: 0;
  font-weight: 700;
  font-family: 'Playfair Display';
  font-style: italic;

  @media (min-width: ${Viewport.MEDIUM}px) {
    font-size: 80px;
    letter-spacing: -0.04em;
  }

  @media (min-width: ${Viewport.LARGE}px) {
    font-size: 104px;
    letter-spacing: -0.04em;
  }
  
`;

const h3Styles = `
  font-size: 12px;
  letter-spacing: 2px;
  margin: 24px 0 1em;
  text-transform: uppercase;

  @media (min-width: ${Viewport.MEDIUM}px) {
    font-size: 13px;
  }
`;

const pStyles = `
  line-height: 24px;
  font-size: 14px;

  @media (min-width: ${Viewport.LARGE}px) {
    line-height: 28px;
    font-size: 15px;
  }
`;

const Id = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: span 5;

  @media (min-width: ${Viewport.MEDIUM}px) {
    grid-column: span 12;
  }

  p {
    ${pStyles}
    margin: 0;
    opacity: 0.33;
    display: inline-block;
    padding: 0 12px;
    white-space: nowrap;
    font-size: 11px;
    font-weight: 600;
    line-height: 24px;
    margin-left: -12px;
    letter-spacing: 2px;
    text-transform: uppercase;

    @media (min-width: ${Viewport.LARGE}px) {
      line-height: 26px;
      font-size: 14px;
      letter-spacing: 3px;
    }
  }
`;

const Heading = styled.div`
  position: relative;
  grid-column: span 5;
  grid-row: 2;
  display: flex;
  align-items: flex-end;
  height: 280px;
  padding-bottom: 10%;
  transform: rotateY(5deg);

  @media (min-width: ${Viewport.MEDIUM}px) {
    grid-column: span 6;
    height: 50vh;
  }

  h1 {
    ${h1Styles}
    padding: 0 0 0.15em;
  }
`;

const Intro = styled.div`
  position: relative;
  grid-column: span 5;
  grid-row: 3;
  margin-bottom: 2em;
  overflow: visible;
  padding: 0 0;

  @media (min-width: ${Viewport.MEDIUM}px) {
    margin-bottom: 0;
  }

  p {
    ${pStyles}
  }

  p::before {
    ${itemDecoration(14)}
  }
`;

const anchorStyles = `
  position: relative;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid var(--color-text-third);
  padding-bottom: 0.2em;
`;

const listStyles = css`
  position: relative;
  grid-column: span 5;
  grid-row: 4;
  margin-bottom: 1em;

  a {
    ${anchorStyles}

    &:hover {
      border-bottom-color: var(--color-text);
      color: var(--bg-page);
      background-color: var(--color-text);
      box-shadow: -3px 0 0 var(--color-text), 3px 0 0 var(--color-text);
    }
  }

  @media (min-width: ${Viewport.MEDIUM}px) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row: 3;
  }

  h3::before {
    ${itemDecoration(24)}
  }

  h3 {
    ${h3Styles}
  }

  p {
    ${pStyles}
  }
`;

const Skills = styled.div`
  ${listStyles}
  grid-row: 4;
`;

const Work = styled.div`
  ${listStyles}
  grid-row: 5;

  @media (min-width: ${Viewport.MEDIUM}px) {
    grid-column-start: 10;
    grid-column-end: 12;
  }
`;

const Footer = styled.div`
  display: flex;
  grid-column: span 5;
  grid-row: 6;
  font-size: 12px;

  @media (min-width: ${Viewport.MEDIUM}px) {
    grid-column: span 12;
    grid-row: 4;
    font-size: 14px;
  }

  a {
    ${anchorStyles}

    &:hover {
      border-bottom-color: var(--color-text);
    }
  }
`;

const FooterInner = styled.div`
  align-self: flex-end;
`;

export default App;
