import React, { useEffect, useState, useRef } from "react";
import { random } from "lodash";
import styled from "styled-components";
import glitch from "glitch-canvas";
import { Viewport } from "../style";

const HEAD_IMAGE = "head-sharp.jpg";
const INITIAL_DELAY = 3000;
const STROKE_ANIMATION_DUR = 600;
const STROKE_ANIMATION_FADEOUT_DELAY = 4000;
const FADE_IN_DUR = 200;
const GLITCH_LEN = 10;

const Avatar: React.FC<{ isStroked: boolean }> = ({ isStroked }) => {
  const [glitches, setGlitches] = useState<any>(null);
  const [isMouseOver, setMouseOver] = useState(false);
  const [rand, setRand] = useState<number | null>(null);
  const requestRef = useRef<any>(null);
  const autoAnimateTimeRef = useRef<any>(0);
  const animateRef = useRef(false);

  const animate = () => {
    if (animateRef.current) {
      setRand(random(0, GLITCH_LEN - 1));
      requestRef.current = requestAnimationFrame(animate);
    }
  };

  const startAutoAnimate = () => {
    if (autoAnimateTimeRef.current) return;

    autoAnimateTimeRef.current = setTimeout(() => {
      startAnimation();
      setTimeout(() => {
        stopAnimation();
        autoAnimateTimeRef.current = 0;
        startAutoAnimate();
      }, random(300, 800));
    }, random(7000, 12000));
  };

  const createGlitches = () => {
    const image = document.createElement("img");
    image.src = `/${HEAD_IMAGE}`;
    image.onload = () => {
      const glitches: any = [];

      for (let i = 0; i < GLITCH_LEN; i++) {
        glitch({
          seed: i * Math.floor(99 / GLITCH_LEN),
        })
          .fromImage(image)
          .toDataURL()
          .then((dataURL: string) => {
            glitches.push(dataURL);
            if (glitches.length === GLITCH_LEN) {
              setGlitches(glitches);
            }
          });
      }
    };
  };

  const endAutoAnimate = () => {
    clearTimeout(autoAnimateTimeRef.current);
    autoAnimateTimeRef.current = 0;
  };

  useEffect(() => {
    if (!glitches) {
      setTimeout(() => createGlitches(), 4000);
    }

    startAutoAnimate();

    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  const startAnimation = () => {
    animateRef.current = true;
    requestRef.current = requestAnimationFrame(animate);
  };

  const stopAnimation = () => {
    setRand(null);
    animateRef.current = false;
    cancelAnimationFrame(requestRef.current);
  };

  useEffect(() => {
    if (isMouseOver) {
      endAutoAnimate();
      startAnimation();
    } else {
      startAutoAnimate();
      stopAnimation();
    }
  }, [isMouseOver]);

  const handleMouseOver = () => setMouseOver(true);
  const handleMouseOut = () => setMouseOver(false);

  return (
    <Root isStroked={isStroked}>
      <svg viewBox="0 0 100 100">
        <circle cx={50} cy={50} r={50} />
      </svg>
      <Image onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
        <img src={`/${HEAD_IMAGE}`} alt="Me" />
        {glitches &&
          glitches.map((g: string, i: number) => (
            <Img src={g} alt="me head" key={i} isActive={i === rand} />
          ))}
      </Image>
    </Root>
  );
};

const Root = styled.div<{ isStroked: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100px;
  width: 100px;
  opacity: 0.9;
  z-index: 3;

  @media (min-width: ${Viewport.MEDIUM}px) {
    top: 0;
    right: 0;
    height: 140px;
    width: 140px;
  }

  @media (min-width: ${Viewport.LARGE}px) {
    height: 190px;
    width: 190px;
  }

  svg {
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    overflow: visible;
    fill: none;
    opacity: 1;
    top: 1px;
    left: 1px;
  }

  circle {
    stroke: var(--bg-grid-line);
    stroke-width: 1;
    stroke-dasharray: 315;
    stroke-dashoffset: ${(p) => (p.isStroked ? "0" : "315")};
    transition: stroke-dashoffset ${STROKE_ANIMATION_DUR}ms;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes stroke {
    from {
      stroke-dashoffset: -315;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Image = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: fadeIn ${FADE_IN_DUR}ms forwards;
  animation-delay: ${INITIAL_DELAY}ms;
  opacity: 0;
  overflow: hidden;
  z-index: 1;
  color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  background: var(--bg-page);

  img {
    object-fit: fill;
    filter: saturate(0) contrast(1.1);
    width: 100%;
    opacity: 0.9;
    top: 0;
    left: 0;
    position: absolute;
  }

  & [data-theme="2"] {
    img {
      mix-blend-mode: darken;
    }
  }
`;

const activeStyles = `
  display: block;
  z-index: 1;
`;

const Img = styled.img<{ isActive: boolean }>`
  display: none;
  ${(p) => p.isActive && activeStyles}
`;

export default Avatar;
