import React from "react";
import styled from "styled-components";

type Props = any;

const GridIcon: React.FC<Props> = ({ size }) => {
  return <Root style={{ height: `${size}px`, width: `${size}px` }}></Root>;
};

const Root = styled.div`
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    border-left: 1px solid var(--color-text);
    border-right: 1px solid var(--color-text);
    height: 60%;
    width: 20%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  &::after {
    height: 20%;
    width: 60%;
    border-top: 1px solid var(--color-text);
    border-bottom: 1px solid var(--color-text);
    border-left: 0;
    border-right: 0;
  }
`;

export default GridIcon;
