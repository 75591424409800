import React from "react";
import styled from "styled-components";

const ANIMATION_DUR = 300;
const SIDE_DELAY = 30;

type Props = {
  delay: number;
  isVisible: boolean;
};

const FrameOutlines: React.FC<Props> = ({ delay, isVisible }) => {
  return <Root delay={delay} isVisible={isVisible} />;
};

const Root = styled.div<{ delay: number; isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;

  ::before,
  ::after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    background-color: var(--bg-grid-line);
    left: 0;
    height: 1px;
    transition: transform ${ANIMATION_DUR}ms;
    transition-delay: ${(p) => p.delay}ms;
  }

  ::before {
    top: 0;
    left: 0;
    transform: translateX(${(p) => (p.isVisible ? "0" : "100%")});
  }

  ::after {
    bottom: 0;
    transform: translateX(${(p) => (p.isVisible ? "0" : "-100%")});
    transition-delay: ${(p) => p.delay + SIDE_DELAY}ms;
`;

export default FrameOutlines;
