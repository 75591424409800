import React, { useLayoutEffect, useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";

const BASE_DELAY = 2000;
const FADE_DUR = 600;
const FADE_DELAY = 3000;
const SLIDE_DUR = 300;

const TextOutlines: React.FC = ({ children }) => {
  const htmlRef = useRef(document.createElement("div"));
  const rootRef = useRef(document.createElement("div"));
  const [isMounted, setMounted] = useState(false);
  const [html, setHtml] = useState("");
  const [lines, setLines] = useState<any>(null);

  const getLines = (node: any, parent: any) => {
    const parentRect = parent.getBoundingClientRect();
    const parentX = parentRect.left;
    const parentY = parentRect.top;

    let x = 0;
    let y = 0;
    let height = 0;
    let width = 0;
    let lines = [];
    let flag = false;
    let childNode: HTMLElement = document.createElement("div");

    for (let i = 0; i < node.children.length; i++) {
      flag = false;
      childNode = node.children[i];

      const rect = childNode.getBoundingClientRect();
      width = rect.width;
      height = rect.height;

      if (!height) {
        continue;
      }

      if (node.children.children) {
        lines.push({
          x: x - width,
          y: y - parentY,
          height,
        });
      }

      if (rect.top !== y) {
        lines.push({
          x: x - parentX,
          y: y - parentY,
          height,
        });
      }

      y = rect.top;
      x = rect.x;
    }

    lines.push({ x: x - parentX + width, y: y - parentY, height });

    return lines;
  };

  useEffect(() => {
    const nodes = [];

    const wrapText = (tag: string, text: string) => {
      return `<${tag}>${wrapSpans(text)}</${tag}>`;
    };

    const wrapSpans = (text: string) => {
      return text.replace(/([a-zA-Z0-9 ,.])/g, "<span>$1</span>");
    };

    const getHtml = (node: any) => {
      const children = (node as React.ReactElement).props?.children;

      if (typeof children === "object") {
        let htmlStr = "";
        for (let i = 0; i < children.length; i++) {
          if (typeof children[i] === "string") {
            htmlStr += wrapSpans(children[i]);
          } else if (children[i]?.type === "a") {
            // wrap with link
            htmlStr += `<a href="${children[i].props.href}">${wrapSpans(
              children[i].props.children
            )}</a>`;
          } else {
            htmlStr += "<br/>";
          }
        }

        return `<p>${htmlStr}</p>`;
      }

      const tag = (node as React.ReactElement).type;
      nodes.push(wrapText(tag as string, children));
    };

    if (children) {
      if (
        typeof children === "object" &&
        !(children as React.ReactNodeArray).length
      ) {
        nodes.push(getHtml(children));
      } else {
        for (let child of children as React.ReactNodeArray) {
          nodes.push(getHtml(child));
        }
      }

      setMounted(true);
      setHtml(nodes.join(""));
    }
  }, [children]);

  useLayoutEffect(() => {
    if (html) {
      const lines = [];
      const node = htmlRef.current as HTMLDivElement;
      const nodes = node.children;
      for (let i = 0; i < nodes.length; i++) {
        lines.push(...getLines(nodes[i], rootRef.current));
      }

      setLines(lines);

      // Clean up the HTML after the animation
      setTimeout(() => {
        const tidyHtml = html.replace(/<span>/g, "").replace(/<\/span>/g, "");
        setHtml(tidyHtml);
      }, FADE_DELAY + FADE_DUR + SLIDE_DUR);
    }
  }, [html]);

  return (
    <Root isMounted={isMounted} ref={rootRef}>
      <Words ref={htmlRef} dangerouslySetInnerHTML={{ __html: html }} />
      {lines && (
        <Lines>
          {lines.map((line: any, i: number) => {
            return (
              <Line
                style={{ height: line.height, width: line.x, top: line.y }}
                index={i}
                key={i}
              >
                <div></div>
              </Line>
            );
          })}
        </Lines>
      )}
    </Root>
  );
};

const unMountedStyles = css`
  * {
    visibility: hidden;
  }
`;

const Root = styled.div<{ isMounted: boolean }>`
  ${(p) => !p.isMounted && unMountedStyles}
  position: relative;
  z-index: 0;
  overflow: hidden;

  @keyframes expand {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Words = styled.div`
  position: relative;
  z-index: 1;

  h3 {
    font-size: 12px;
    letter-spacing: 2px;
    margin: 24px 0 1em;
    text-transform: uppercase;
  }

  p {
    line-height: 28px;
  }
  opacity: 0;
  animation: fadeIn ${FADE_DUR}ms forwards;
  animation-delay: ${FADE_DELAY}ms;
`;

const Lines = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: fadeOut ${FADE_DUR}ms forwards;
  animation-delay: ${FADE_DELAY}ms;
`;

const Line = styled.div<{ index: number }>`
  position: absolute;

  > div {
    position: absolute;
    top: 15%;
    bottom: 15%;
    width: 100%;
    background: var(--bg-skeleton);
    animation: expand ${SLIDE_DUR}ms forwards;
    transform: scaleX(0);
    transform-origin: 0 0;
    animation-delay: ${(p) => p.index * 50 + BASE_DELAY}ms;
  }
`;

export default TextOutlines;
