export const itemDecoration = (offset?: number) => `
    position: absolute;
    height: 1px;
    width: 48px;
    background-color: var(--color-accent);
    content: "";
    display: block;
    left: 0;
    top: -${offset || 16}px;
`;

export const absoluteFill = `
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export enum Viewport {
  LARGE = 1024,
  MEDIUM = 768,
  SMALL = 375,
}

export const gridStyles = `
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, max-content) 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  min-height: 100vh;
  padding-top: 32px;
  padding-bottom: 32px;

  @media (min-width: ${Viewport.MEDIUM}px) {
    grid-template-columns: repeat(12, 1fr);
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;
